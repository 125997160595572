import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  User, AssessmentDomain, QuestionType, Complexity, Question, Answer, CompanyUser, userDetails,
  Assessment, AssessmentQuestion, AssessmentLibrary, LibraryQuestion, Role, RegisterAssessment
  , UserAssessment, Compiler, AssessmentQuestionsViewModel, AssessmentQuestionNew, PrimaryDomain, ProctorResults, SkillReportModel
} from '@app/_models';
import { ConfigurationService } from './deployment/configuration-service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private userSubject: BehaviorSubject<User>;
  private userDeatilsSubject: BehaviorSubject<userDetails>;
  domainUrl: string;
  questiontypeUrl: string;
  complexityUrl: string;
  questionUrl: string;
  answerUrl: string;
  userUrl: string;
  assessmentUrl: string;
  assessmentLibUrl: string;
  assessmentQuesUrl: string;
  libraryQuesUrl: string;
  roleUrl: string;
  regAssessmentUrl: string;
  user: User;
  candidateListUrl: string;
  subUsersListUrl: string;
  changePasswordUrl: string;
  userAssessmentUrl: string;
  questionsForAssessmentUrl: string;
  assessmentQuestionsByAssessmentIdUrl: string;
  compilerUrl: string;
  assessmentQuestionsViewUrl: string;
  saveAsessmentQuestionUrl: string;
  getPrimaryDomainUrl: string;
  getPrimaryDomainByIdUrl: string;
  savePrimaryDomainUrl: string;
  getAssDomainByPrimDomainIdUrl: string;
  getJwtTokenUrl: string;
  addAssessmentDetailsUrl: string;
  freezeAssessmenturl: string;
  getAssessmentDetailsForTesturl: string;
  getProctorResults: string;
  getSkillReportUrl: string;
  downloadProctorResultsPDF: string;

  constructor(
    private http: HttpClient,
    private appConfig: ConfigurationService,
    private accountService: AccountService
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.userDeatilsSubject = new BehaviorSubject<userDetails>(JSON.parse(localStorage.getItem('userdetails')));
    this.domainUrl = this.appConfig.getConfig().endpoints.domain;
    this.compilerUrl = this.appConfig.getConfig().endpoints.compiler;
    this.questiontypeUrl = this.appConfig.getConfig().endpoints.questiontype;
    this.complexityUrl = this.appConfig.getConfig().endpoints.complexity;
    this.questionUrl = this.appConfig.getConfig().endpoints.questions;
    this.answerUrl = this.appConfig.getConfig().endpoints.answers;
    this.userUrl = this.appConfig.getConfig().endpoints.companyUser;
    this.assessmentUrl = this.appConfig.getConfig().endpoints.assessment;
    this.assessmentLibUrl = this.appConfig.getConfig().endpoints.assessmentLib;
    this.assessmentQuesUrl = this.appConfig.getConfig().endpoints.assessmentQues;
    this.libraryQuesUrl = this.appConfig.getConfig().endpoints.libraryQues;
    this.roleUrl = this.appConfig.getConfig().endpoints.role;
    this.regAssessmentUrl = this.appConfig.getConfig().endpoints.registerAssessment;
    this.subUsersListUrl = this.appConfig.getConfig().endpoints.subUsersList;
    this.candidateListUrl = this.appConfig.getConfig().endpoints.candidateList;
    this.changePasswordUrl = this.appConfig.getConfig().endpoints.changePassword;
    this.userAssessmentUrl = this.appConfig.getConfig().endpoints.getUserAssessment;
    this.questionsForAssessmentUrl = this.appConfig.getConfig().endpoints.getQuestionsForAssessment;
    this.assessmentQuestionsByAssessmentIdUrl = this.appConfig.getConfig().endpoints.getAssessmentQuestionById;
    this.assessmentQuestionsViewUrl = this.appConfig.getConfig().endpoints.getAssessmentQuestionsView;
    this.saveAsessmentQuestionUrl = this.appConfig.getConfig().endpoints.saveAssessmentQuestion;
    this.getPrimaryDomainUrl = this.appConfig.getConfig().endpoints.getPrimaryDomain;
    this.getPrimaryDomainByIdUrl = this.appConfig.getConfig().endpoints.getPrimaryDomainById;
    this.savePrimaryDomainUrl = this.appConfig.getConfig().endpoints.savePrimaryDomain;
    this.getAssDomainByPrimDomainIdUrl = this.appConfig.getConfig().endpoints.getAssDomainByPrimDomain;
    this.getJwtTokenUrl = this.appConfig.getConfig().endpoints.getJwtToken;
    this.addAssessmentDetailsUrl = this.appConfig.getConfig().endpoints.addAssessmentDetails;
    this.freezeAssessmenturl = this.appConfig.getConfig().endpoints.freezeAssessment;
    this.getAssessmentDetailsForTesturl = this.appConfig.getConfig().endpoints.getAssessmentDetailsForTest;
    this.getProctorResults = this.appConfig.getConfig().endpoints.getProctorResults;
    this.getSkillReportUrl = this.appConfig.getConfig().endpoints.getSkillReport;
    this.downloadProctorResultsPDF = this.appConfig.getConfig().endpoints.downloadProcotorPdf;
  }

  getDomainList() {
    var userdata = this.accountService.userValue;
    return this.http.get<AssessmentDomain[]>(this.domainUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getDomainListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<AssessmentDomain>(`${this.domainUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addDomain(domainName: string, domainShortName: string, CompilerId: string, primaryDomainId: string) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('DomainName', domainName);
    urlSearchParams.append('DomainShortName', domainShortName);
    urlSearchParams.append('CompilerId', CompilerId);
    urlSearchParams.append('PrimaryDomainId', primaryDomainId);
    let body = urlSearchParams.toString();
    return this.http.post<AssessmentDomain>(this.domainUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  updateDomain(domainId: number, domainName: string, domainShortName: string, CompilerId: string, primaryDomainId: string) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('DomainId', domainId.toString());
    urlSearchParams.append('DomainName', domainName);
    urlSearchParams.append('DomainShortName', domainShortName);
    urlSearchParams.append('PrimaryDomainId', primaryDomainId?.toString());
    urlSearchParams.append('CompilerId', CompilerId);
    let body = urlSearchParams.toString();
    return this.http.put<AssessmentDomain>(`${this.domainUrl}/${domainId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  DeleteDomain(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<AssessmentDomain>(`${this.domainUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getCompilerList() {
    var userdata = this.accountService.userValue;
    return this.http.get<Compiler[]>(this.compilerUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getQuestionTypeList() {
    var userdata = this.accountService.userValue;
    return this.http.get<QuestionType[]>(this.questiontypeUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getQuestionTypeListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<QuestionType>(`${this.questiontypeUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addQuestionType(questiontypeData: QuestionType) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('TypeDescription', questiontypeData.TypeDescription);
    urlSearchParams.append('MultipleChoice', questiontypeData.MultipleChoice == null ? "false" : questiontypeData.MultipleChoice.toString());
    urlSearchParams.append('Coding', questiontypeData.Coding == null ? "false" : questiontypeData.Coding.toString());
    urlSearchParams.append('AllowMultipleSelection', questiontypeData.AllowMultipleSelection == null ? "false" : questiontypeData.AllowMultipleSelection.toString());
    let body = urlSearchParams.toString();
    return this.http.post<QuestionType>(this.questiontypeUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  updateQuestionType(questiontypeData: QuestionType) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('TypeDescription', questiontypeData.TypeDescription);
    urlSearchParams.append('MultipleChoice', questiontypeData.MultipleChoice == null ? "false" : questiontypeData.MultipleChoice.toString());
    urlSearchParams.append('Coding', questiontypeData.Coding == null ? "false" : questiontypeData.Coding.toString());
    urlSearchParams.append('AllowMultipleSelection', questiontypeData.AllowMultipleSelection == null ? "false" : questiontypeData.AllowMultipleSelection.toString());
    let body = urlSearchParams.toString();
    return this.http.put<QuestionType>(`${this.questiontypeUrl}/${questiontypeData.TypeId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  DeleteQuestionType(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<QuestionType>(`${this.questiontypeUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getComplexityList() {
    var userdata = this.accountService.userValue;
    return this.http.get<Complexity[]>(this.complexityUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getComplexityListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<Complexity>(`${this.complexityUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addComplexity(complexityData: Complexity) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('ComplexityLevel', complexityData.ComplexityLevel);
    let body = urlSearchParams.toString();
    return this.http.post<Complexity>(this.complexityUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  updateComplexity(complexityData: Complexity) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('ComplexityLevel', complexityData.ComplexityLevel);
    let body = urlSearchParams.toString();
    return this.http.put<Complexity>(`${this.complexityUrl}/${complexityData.ComplexityId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  DeleteComplexity(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<Complexity>(`${this.complexityUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getQuestionsList() {
    var userdata = this.accountService.userValue;
    return this.http.get<Question[]>(this.questionUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getQuestionsListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<Question>(`${this.questionUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addQuestions(questionData: Question) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    var json_que = JSON.stringify(questionData);
    var json_ans = JSON.stringify(questionData.answers);
    urlSearchParams.append('questionModel', json_que);
    let body = {
      QuestionText: questionData.QuestionText,
      DomainId: questionData.DomainId.toString(),
      ComplexityId: questionData.ComplexityId.toString(),
      QuestionTypeId: questionData.QuestionTypeId.toString(),
      answers: questionData.QuestionTypeId == 6 ? [] : questionData.answers
    };
    return this.http.post<Question>(this.questionUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }

  updateQuestions(questionData: Question) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    var json_que = JSON.stringify(questionData);
    urlSearchParams.append('questionModel', json_que);
    let body = {
      QuestionId: questionData.QuestionId,
      QuestionText: questionData.QuestionText,
      DomainId: questionData.DomainId.toString(),
      ComplexityId: questionData.ComplexityId.toString(),
      QuestionTypeId: questionData.QuestionTypeId.toString(),
      answers: questionData.QuestionTypeId == 6 ? [] : questionData.answers
    };
    return this.http.put<Question>(`${this.questionUrl}/${questionData.QuestionId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }

  DeleteQuestions(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<Question>(`${this.questionUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getQuestionsForAssessmentList(domainId: Number, complexityId: Number, questionTypeId: Number) {
    var userdata = this.accountService.userValue;
    return this.http.get<Question[]>(`${this.questionsForAssessmentUrl}/${domainId}/${complexityId}/${questionTypeId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getAssessmentQuestionsByAssessmentId(assessmentId: Number) {
    var userdata = this.accountService.userValue;
    return this.http.get<Question[]>(`${this.assessmentQuestionsByAssessmentIdUrl}/${assessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getAnswersList() {
    var userdata = this.accountService.userValue;
    return this.http.get<Answer[]>(this.answerUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getAnswersListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<Answer>(`${this.answerUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addAnswers(answerData: Answer) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('AnswerText', answerData.AnswerText);
    urlSearchParams.append('QuestionId', answerData.QuestionId.toString());
    urlSearchParams.append('AnswerOrder', answerData.AnswerOrder.toString());
    urlSearchParams.append('CorrectAnswer', answerData.CorrectAnswer == null ? "false" : answerData.CorrectAnswer.toString());
    let body = urlSearchParams.toString();
    return this.http.post<Answer>(this.answerUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  updateAnswers(answerData: Answer) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('AnswerText', answerData.AnswerText);
    urlSearchParams.append('QuestionId', answerData.QuestionId.toString());
    urlSearchParams.append('AnswerOrder', answerData.AnswerOrder.toString());
    urlSearchParams.append('CorrectAnswer', answerData.CorrectAnswer == null ? "false" : answerData.CorrectAnswer.toString());
    let body = urlSearchParams.toString();
    return this.http.put<Answer>(`${this.answerUrl}/${answerData.AnswerId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  DeleteAnswers(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<Answer>(`${this.answerUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getUsersList() {
    var userdata = this.accountService.userValue;
    return this.http.get<CompanyUser[]>(this.userUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getSubUsersList() {
    var userdata = this.accountService.userValue;
    return this.http.get<CompanyUser[]>(this.subUsersListUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getCandidatesList(AssessmentId: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<CompanyUser[]>(`${this.candidateListUrl}/${AssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getUsersListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<CompanyUser>(`${this.userUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addUsers(UserName: string, Name: string, RoleId: number, Password: string) {
    var userdata = this.accountService.userValue;
    var userdetails = this.userDeatilsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userdetails'))).value.data;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('ClientId', userdetails.ClientId.toString());
    urlSearchParams.append('UserName', UserName);
    urlSearchParams.append('Password', Password);
    urlSearchParams.append('Name', Name);
    urlSearchParams.append('RoleId', RoleId.toString());
    let body = urlSearchParams.toString();
    return this.http.post<CompanyUser>(this.userUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  updateUsers(UserId: number, UserName: string, Name: string, RoleId: number) {
    var userdata = this.accountService.userValue;
    var userdetails = this.userDeatilsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userdetails'))).value.data;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('ClientId', userdetails.ClientId.toString());
    urlSearchParams.append('userName', UserName);
    urlSearchParams.append('Name', Name);
    urlSearchParams.append('RoleId', RoleId.toString());
    let body = urlSearchParams.toString();
    return this.http.put<CompanyUser>(`${this.userUrl}/${UserId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  DeleteUsers(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<CompanyUser>(`${this.userUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }
  //#

  getAssessmentsList() {
    var userdata = this.accountService.userValue;
    return this.http.get<Assessment[]>(this.assessmentUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getAssessmentsListById(Id: Number) {
    var userdata = this.accountService.userValue;
    return this.http.get<Assessment>(`${this.assessmentUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getAssessmentsListByIdForTest(Id: Number) {
    var userdata = this.accountService.userValue;
    return this.http.get<Assessment>(`${this.getAssessmentDetailsForTesturl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addAssessments(assessmentData: Assessment) {
    var userdata = this.accountService.userValue;
    var userdetails = this.userDeatilsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userdetails'))).value.data;
    assessmentData.ClientId = userdetails.ClientId.toString();
    let body = {
      AssessmentId: assessmentData.AssessmentId,
      ClientId: assessmentData.ClientId,
      AssessmentName: assessmentData.AssessmentName,
      TimeLimitInMinutes: assessmentData.TimeLimitInMinutes,
      ReadQuestionsFromAssessment: assessmentData.ReadQuestionsFromAssessment,
      IsProctorRequired: assessmentData.IsProctorRequired
    };
    return this.http.post<Assessment>(this.assessmentUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }

  updateAssessments(assessmentData: Assessment) {
    var userdata = this.accountService.userValue;
    var userdetails = this.userDeatilsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userdetails'))).value.data;
    assessmentData.ClientId = userdetails.ClientId.toString();
    let body = {
      AssessmentId: assessmentData.AssessmentId,
      ClientId: assessmentData.ClientId,
      AssessmentName: assessmentData.AssessmentName,
      TimeLimitInMinutes: assessmentData.TimeLimitInMinutes,
      ReadQuestionsFromAssessment: assessmentData.ReadQuestionsFromAssessment ? assessmentData.ReadQuestionsFromAssessment : false,
      IsProctorRequired: assessmentData.IsProctorRequired
    };
    return this.http.put<Assessment>(`${this.assessmentUrl}/${assessmentData.AssessmentId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }

  DeleteAssessments(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<Assessment>(`${this.assessmentUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  freezeAssessments(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.post<Assessment>(`${this.freezeAssessmenturl}/${Id}`, null, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addAssessmentDetails(assessmentData: Assessment) {
    var userdata = this.accountService.userValue;
    var userdetails = this.userDeatilsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userdetails'))).value.data;
    assessmentData.ClientId = userdetails.ClientId.toString();
    let urlSearchParams = new URLSearchParams();
    let body = {
      AssessmentId: assessmentData.AssessmentId,
      ClientId: assessmentData.ClientId,
      AssessmentName: assessmentData.AssessmentName,
      TimeLimitInMinutes: assessmentData.TimeLimitInMinutes,
      ReadQuestionsFromAssessment: assessmentData.ReadQuestionsFromAssessment,
      assessmentDetails: assessmentData.assessmentDetails
    };
    return this.http.post<Assessment>(this.addAssessmentDetailsUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }

  getAssessmentLibrariesList() {
    var userdata = this.accountService.userValue;
    return this.http.get<AssessmentLibrary[]>(this.assessmentLibUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getAssessmentLibrariesListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<AssessmentLibrary>(`${this.assessmentLibUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addAssessmentLibraries(assessLibData: AssessmentLibrary) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('LibraryName', assessLibData.LibraryName);
    urlSearchParams.append('DomainId', assessLibData.DomainId.toString());
    let body = urlSearchParams.toString();
    return this.http.post<AssessmentLibrary>(this.assessmentLibUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  updateAssessmentLibraries(assessLibData: AssessmentLibrary) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('LibraryName', assessLibData.LibraryName);
    urlSearchParams.append('DomainId', assessLibData.DomainId.toString());
    let body = urlSearchParams.toString();
    return this.http.put<AssessmentLibrary>(`${this.assessmentLibUrl}/${assessLibData.LibraryId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  DeleteAssessmentLibraries(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<AssessmentLibrary>(`${this.assessmentLibUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getAssessmentQuestionsList() {
    var userdata = this.accountService.userValue;
    return this.http.get<AssessmentQuestion[]>(this.assessmentQuesUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getAssessmentQuestionsListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<AssessmentQuestion>(`${this.assessmentQuesUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addAssessmentQuestions(assessmentQuesData: AssessmentQuestion) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('AssessmentId', assessmentQuesData.AssessmentId.toString());
    urlSearchParams.append('QuestionId', assessmentQuesData.QuestionId.toString());
    urlSearchParams.append('AssessmentOrder', assessmentQuesData.AssessmentOrder == null ? "0" : assessmentQuesData.AssessmentOrder.toString());
    let body = urlSearchParams.toString();
    return this.http.post<AssessmentQuestion>(this.assessmentQuesUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  updateAssessmentQuestions(assessmentQuesData: AssessmentQuestion) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('AssessmentId', assessmentQuesData.AssessmentId.toString());
    urlSearchParams.append('QuestionId', assessmentQuesData.QuestionId.toString());
    urlSearchParams.append('AssessmentOrder', assessmentQuesData.AssessmentOrder == null ? "0" : assessmentQuesData.AssessmentOrder.toString());
    let body = urlSearchParams.toString();
    return this.http.put<AssessmentQuestion>(`${this.assessmentQuesUrl}/${assessmentQuesData.AssessmentQuestionId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  DeleteAssessmentQuestions(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<AssessmentQuestion>(`${this.assessmentQuesUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getAssessmentQuestionsView(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<AssessmentQuestionsViewModel>(`${this.assessmentQuestionsViewUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  saveAssessmentQuestions(questionData: AssessmentQuestionNew) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    var json_que = JSON.stringify(questionData);
    var json_ans = JSON.stringify(questionData.QuestionModel.answers);
    urlSearchParams.append('questionModel', json_que);
    let question = {
      QuestionText: questionData.QuestionModel.QuestionText,
      DomainId: questionData.QuestionModel.DomainId.toString(),
      ComplexityId: questionData.QuestionModel.ComplexityId.toString(),
      QuestionTypeId: questionData.QuestionModel.QuestionTypeId.toString(),
      answers: questionData.QuestionModel.QuestionTypeId == 6 ? [] : questionData.QuestionModel.answers
    };
    let body = {
      AssessmentId: questionData.AssessmentId,
      QuestionModel: question
    };
    return this.http.post<AssessmentQuestionNew>(this.saveAsessmentQuestionUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }

  getLibraryQuestionsList() {
    var userdata = this.accountService.userValue;
    return this.http.get<LibraryQuestion[]>(this.libraryQuesUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getLibraryQuestionsListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<LibraryQuestion>(`${this.libraryQuesUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addLibraryQuestions(libraryQuesData: LibraryQuestion) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('AssessmentId', libraryQuesData.AssessmentId.toString());
    urlSearchParams.append('QuestionId', libraryQuesData.QuestionId.toString());
    urlSearchParams.append('Order', libraryQuesData.Order.toString());
    let body = urlSearchParams.toString();
    return this.http.post<LibraryQuestion>(this.libraryQuesUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  updateLibraryQuestions(libraryQuesData: LibraryQuestion) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('AssessmentId', libraryQuesData.AssessmentId.toString());
    urlSearchParams.append('QuestionId', libraryQuesData.QuestionId.toString());
    urlSearchParams.append('Order', libraryQuesData.Order.toString());
    let body = urlSearchParams.toString();
    return this.http.put<LibraryQuestion>(`${this.libraryQuesUrl}/${libraryQuesData.LibraryQuestionId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  DeleteLibraryQuestions(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.delete<LibraryQuestion>(`${this.libraryQuesUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getRolesList() {
    var userdata = this.accountService.userValue;
    return this.http.get<Role[]>(this.roleUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getRolesListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<Role>(`${this.roleUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  registerAssessment(registerAssessment: RegisterAssessment) {
    var userdata = this.accountService.userValue;
    var userdetails = this.userDeatilsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userdetails'))).value.data;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('ClientId', userdetails.ClientId.toString());
    urlSearchParams.append('UserId', registerAssessment.UserId.toString());
    urlSearchParams.append('AssessmentId', registerAssessment.AssessmentId.toString());
    urlSearchParams.append('CCEmailIds', registerAssessment.CCEmailIds);
    let body = urlSearchParams.toString();
    return this.http.post<RegisterAssessment>(this.regAssessmentUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  updateChangePasswrod(Password: string, NewPassword: string) {
    var userdata = this.accountService.userValue;
    var userdetails = this.userDeatilsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userdetails'))).value.data;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('UserId', userdetails.UserId.toString());
    urlSearchParams.append('Password', Password);
    urlSearchParams.append('NewPassword', NewPassword);
    let body = urlSearchParams.toString();
    return this.http.post<any>(this.changePasswordUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }

  getUserAssessmentList() {
    var userdata = this.accountService.userValue;
    return this.http.get<UserAssessment[]>(this.userAssessmentUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getPrimaryDomainList() {
    var userdata = this.accountService.userValue;
    return this.http.get<PrimaryDomain[]>(this.getPrimaryDomainUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getPrimaryDomainListById(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<PrimaryDomain>(`${this.getPrimaryDomainByIdUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  addPrimaryDomain(domainName: string, domainShortName: string) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('PrimaryDomainId', "0");
    urlSearchParams.append('PrimaryDomainName', domainName);
    urlSearchParams.append('PrimaryDomainShortName', domainShortName);
    let body = urlSearchParams.toString();
    return this.http.post<PrimaryDomain>(this.savePrimaryDomainUrl, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }
  updatePrimaryDomain(PrimaryDomainId: number, domainName: string, domainShortName: string) {
    var userdata = this.accountService.userValue;
    let body = {
      PrimaryDomainId: PrimaryDomainId,
      PrimaryDomainName: domainName,
      PrimaryDomainShortName: domainShortName
    }
    return this.http.put<PrimaryDomain>(`${this.domainUrl}/${PrimaryDomainId}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }

  getAssessmentDomainListByPrimDomainId(Id: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<AssessmentDomain>(`${this.getAssDomainByPrimDomainIdUrl}/${Id}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getJwtTokenData(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.getJwtTokenUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getProctorResultsByAssessmentId(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<ProctorResults>(`${this.getProctorResults}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getSkillReportData(primaryDomainId: Number,domainId: Number) {
    var userdata = this.accountService.userValue;
    return this.http.get<SkillReportModel[]>(`${this.getSkillReportUrl}/${primaryDomainId}/${domainId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getProctorReportData(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get(`${this.downloadProctorResultsPDF}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token), responseType: 'blob'} );
  }
}